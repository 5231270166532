<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Finish"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="formSubmitted"
    >

      <!-- Master details tab -->
      <tab-content
        title="Master Details"
        :before-change="validationForm"
      >
        <validation-observer
          ref="masterRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Master Details
              </h5>
              <small class="text-muted">
                Enter Your Master Details.
              </small>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Field Force"
                rules="required"
              >
                <b-form-group
                  label="Field Force"
                  label-for="field-force"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="field-force"
                    v-model="selectedFieldForce"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionFieldForce"
                    :selectable="option => ! option.value.includes('select_value')"
                    label="text"
                    @input="(val) => onSelectedMaster(val,'Field Force')"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Store"
                rules="required"
              >
                <b-form-group
                  label="Store"
                  label-for="store"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="store"
                    v-model="selectedStore"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionStore"
                    :selectable="option => ! option.value.includes('select_value')"
                    label="text"
                    @input="(val) => onSelectedMaster(val,'Store')"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Product"
                rules="required"
              >
                <b-form-group
                  label="Product"
                  label-for="product"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="Product"
                    v-model="selectedProduct"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionProduct"
                    :selectable="option => ! option.value.includes('select_value')"
                    label="text"
                    @input="(val) => onSelectedMaster(val,'Product')"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- personal details tab -->
      <tab-content
        title="Price Info"
      >
        <b-row
          class="invoice-preview"
        >
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Price Info
            </h5>
            <small class="text-muted">Here your price detail</small>
          </b-col>
          <b-col
            cols="12"
            xl="12"
            md="8"
          >
            <b-card
              no-body
              class="invoice-preview-card"
            >

              <!-- Invoice Description: Table -->
              <b-table-lite
                responsive
                :items="priceTableList"
                :fields="['masterDescription', 'rate', 'total', 'price']"
              >
                <template #cell(masterDescription)="data">
                  <b-card-text class="font-weight-bold mb-25">
                    {{ data.item.title }}
                  </b-card-text>
                  <b-card-text class="text-nowrap">
                    {{ data.item.masterDescription }}
                  </b-card-text>
                </template>
              </b-table-lite>

              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-0">
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-6"
                    order="1"
                    order-md="1"
                  />
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-6 d-flex justify-content-end"
                    order="2"
                    order-md="2"
                  >
                    <div class="invoice-total-wrapper">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Subtotal:
                        </p>
                        <p class="invoice-total-amount">
                          {{ priceSubtotal }}
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Tax:
                        </p>
                        <p class="invoice-total-amount">
                          {{ priceTax }}
                        </p>
                      </div>
                      <hr class="my-50">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Total:
                        </p>
                        <p class="invoice-total-amount">
                          {{ priceTotal }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Note -->
              <b-card-body class="invoice-padding pt-0">
                <span class="font-weight-bold">Note: </span>
                <span>The Price is estimated only, to see actual price please go to
                  <b-link :to="{ name: 'dashboard', params: {} }">Buy Package</b-link> Page</span>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>

  </div>
</template>

<script>
import { makeToast, numberWithCommas } from '@/@fake-db/utils'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BLink,
} from 'bootstrap-vue'
import { required, email } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BLink,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
  },
  data() {
    return {
      selectedFieldForce: '',
      optionFieldForce: [],
      selectedStore: '',
      optionStore: [],
      selectedProduct: '',
      optionProduct: [],
      priceTableList: [],
      totalPrice: '0',
      priceSubtotal: 0,
      priceTaxRaw: 11,
      priceTax: '11%',
      priceTotal: 0,
      required,
      email,
    }
  },
  created() {
    // data
    this.$http.get('/console/fieldForceOption')
      .then(response => { this.optionFieldForce = response.data }).catch(error => {
        makeToast(this, `Field Force Option ${error.message}`, 'AlertTriangleIcon', 'danger')
      })
    this.$http.get('/console/storeOption')
      .then(response => { this.optionStore = response.data }).catch(error => {
        makeToast(this, `Store Option ${error.message}`, 'AlertTriangleIcon', 'danger')
      })
    this.$http.get('/console/productOption')
      .then(response => { this.optionProduct = response.data }).catch(error => {
        makeToast(this, `Product Option ${error.message}`, 'AlertTriangleIcon', 'danger')
      })
    this.$http.get('/console/priceList')
      .then(response => { this.priceTableList = response.data }).catch(error => {
        makeToast(this, `Product Option ${error.message}`, 'AlertTriangleIcon', 'danger')
      })
  },
  methods: {
    onSelectedMaster(option, type) {
      this.priceTableList = this.priceTableList.map(element => {
        if (element.title === type) {
          // eslint-disable-next-line no-param-reassign
          element.total = option.value
          // eslint-disable-next-line no-param-reassign
          element.priceRaw = element.total * element.rawRate
          // eslint-disable-next-line no-param-reassign
          element.price = numberWithCommas(element.priceRaw)
        }
        return element
      })
    },
    formSubmitted() {
      makeToast(this, 'Form Submitted', 'EditIcon', 'success')
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.masterRules.validate().then(success => {
          if (success) {
            let totalPrice = 0
            // eslint-disable-next-line no-restricted-syntax
            for (const i of this.priceTableList) {
              // eslint-disable-next-line radix
              totalPrice += parseInt(i.priceRaw)
            }
            this.priceSubtotal = numberWithCommas(totalPrice)
            // eslint-disable-next-line radix
            this.priceTotal = numberWithCommas(parseInt(totalPrice * 1.11))
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>
